<template>
  <card-container backButton closeButton>
    <div class="flex flex-col items-center h-full px-12 pt-20 pb-10">
      <div class="text-3xl text-center">Endre bruker</div>
      <user-photo-select
        :gravatar-email="gravatarEmail"
        @gravatar-url="onGravatarUrl"
        @photo-selected="onPhotoSelected"
        @photo-deleted="onPhotoDeleted"
        class="mt-6"
      />
      <form @submit.prevent="updateUser" class="flex flex-col items-center w-full gap-2 mt-4">
        <input
          type="text"
          ref="displayNameInput"
          v-model="user.displayName"
          placeholder="Navn"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': displayNameInvalid }"
        />
        <input
          type="text"
          ref="emailInput"
          v-model="user.email"
          @blur="gravatarEmail = user.email"
          placeholder="E-post"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': emailInvalid }"
        />
        <input
          type="password"
          ref="passwordInput"
          v-if="user.email !== $store.state.user.email"
          v-model="user.password"
          placeholder="Passord (kreves for å endre e-post)"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': passwordInvalid }"
        />
        <input
          type="submit"
          :value="loading ? 'Lagrer...' : 'Lagre'"
          :disabled="loading"
          class="px-10 py-4 mt-6 text-lg bg-gray-200 rounded-full disabled:opacity-50"
        />
      </form>
      <div class="flex-grow mt-6">
        <transition name="fade">
          <div v-show="error" class="text-center text-red-600">{{ errorMessage }}</div>
        </transition>
      </div>
      <div class="flex justify-center gap-8 mt-6">
        <button
          @click="
            $router.push({ name: 'change-password', params: { transition: 'slide-forward' } })
          "
        >
          Endre passord
        </button>
        <button
          @click="$router.push({ name: 'delete-user', params: { transition: 'slide-forward' } })"
        >
          Slett bruker
        </button>
      </div>
    </div>
  </card-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import CardContainer from '@/components/CardContainer.vue'
import UserPhotoSelect from '@/components/UserPhotoSelect.vue'

export default {
  name: 'UserEdit',
  components: { CardContainer, UserPhotoSelect },
  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = ref(false)
    const error = ref(false)
    const errorMessage = ref('')
    const gravatarEmail = ref()

    const user = ref({
      photo: null,
      photoURL: null,
      photoDeleted: false,
      displayName: store.state.user.displayName,
      email: store.state.user.email,
      password: '',
    })

    const displayNameInput = ref()
    const displayNameInvalid = ref(false)
    const emailInput = ref()
    const emailInvalid = ref(false)
    const passwordInput = ref()
    const passwordInvalid = ref(false)

    const onGravatarUrl = url => {
      if (user.value.photo == null) {
        user.value.photoURL = url
      }
    }

    const onPhotoSelected = photo => {
      user.value.photo = photo
    }

    const onPhotoDeleted = () => {
      user.value.photo = null
      user.value.photoURL = null
      user.value.photoDeleted = true
    }

    const updateUser = () => {
      loading.value = true
      error.value = false
      errorMessage.value = ''
      displayNameInvalid.value = false
      emailInvalid.value = false
      passwordInvalid.value = false

      if (user.value.displayName === '') {
        loading.value = false
        error.value = true
        errorMessage.value = 'Vennligst fyll inn navnet ditt'
        displayNameInput.value.focus()
        displayNameInvalid.value = true
        return
      }

      store
        .dispatch('updateUser', user.value)
        .then(() => {
          router.push({ name: 'user-confirmation', params: { transition: 'slide-forward' } })
        })
        .catch(err => {
          error.value = true
          loading.value = false
          switch (err.code) {
            case 'auth/invalid-email':
              emailInput.value.focus()
              emailInvalid.value = true
              errorMessage.value = 'Ikke en gyldig e-postadresse'
              break
            case 'auth/email-already-in-use':
              emailInput.value.focus()
              emailInvalid.value = true
              errorMessage.value = 'Det finnes allerede en bruker med denne e-postadressen'
              break
            case 'auth/argument-error':
              user.value.password = ''
              passwordInput.value.focus()
              passwordInvalid.value = true
              errorMessage.value = 'Passord er ikke riktig'
              break
            case 'auth/wrong-password':
              user.value.password = ''
              passwordInput.value.focus()
              passwordInvalid.value = true
              errorMessage.value = 'Passord er ikke riktig'
              break
            default:
              errorMessage.value = err.message
          }
        })
    }

    return {
      loading,
      error,
      errorMessage,
      gravatarEmail,
      user,
      displayNameInput,
      displayNameInvalid,
      emailInput,
      emailInvalid,
      passwordInput,
      passwordInvalid,
      onGravatarUrl,
      onPhotoSelected,
      onPhotoDeleted,
      updateUser,
    }
  },
}
</script>
