<template>
  <div class="relative perspective-400">
    <input ref="fileInput" type="file" class="hidden" accept="image/*" @change="onFilePicked" />
    <img
      class="hidden"
      :src="imagePreview"
      @load="imagePreviewLoaded = true"
      alt=""
    /><!-- Preload image -->
    <transition :name="imageTransitionName" mode="out-in">
      <div
        v-if="!imagePreviewLoaded"
        key="select"
        class="flex items-center justify-center bg-gray-200 rounded-full cursor-pointer w-28 h-28"
      >
        <div @click="selectImage()" class="text-center">Velg<br />profilbilde</div>
      </div>
      <div v-else key="image">
        <img class="object-cover rounded-full w-28 h-28" :src="imagePreview" alt="Profile photo" />
      </div>
    </transition>
    <transition name="fade">
      <button
        v-show="imagePreviewLoaded"
        @click="deleteImage"
        class="absolute flex items-center justify-center text-white bg-gray-600 rounded-full -top-1 -right-1 w-9 h-9 focus:outline-none"
      >
        <i class="fa-light fa-xmark"></i>
      </button>
    </transition>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'UserPhotoSelect',
  props: {
    gravatarEmail: String,
  },
  emits: ['gravatarUrl', 'photoSelected', 'photoDeleted'],
  setup(props, { emit }) {
    const store = useStore()
    const fileInput = ref()
    const imagePreview = ref()
    const imagePreviewLoaded = ref(false)
    const imageTransitionName = ref('')

    if (store.state.user && store.state.user.photoURL) {
      imagePreview.value = store.state.user.photoURL
      imagePreviewLoaded.value = true
    }

    watch(
      () => props.gravatarEmail,
      () => {
        if (imagePreview.value == null && props.gravatarEmail !== '') checkForGravatar()
      },
    )

    const selectImage = () => {
      fileInput.value.click()
    }

    const showImage = image => {
      imagePreview.value = image
      imageTransitionName.value = 'spin-forward-90'
    }

    const deleteImage = () => {
      fileInput.value.value = null
      imagePreview.value = null
      imagePreviewLoaded.value = false
      imageTransitionName.value = 'spin-backward-90'
      emit('photoDeleted')
    }

    const onFilePicked = event => {
      if (event.target.value.length === 0) return
      const file = event.target.files[0]
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        showImage(fileReader.result)
      })
      fileReader.readAsDataURL(file)
      emit('photoSelected', file)
    }

    const checkForGravatar = () => {
      store.dispatch('getGravatarUrl', props.gravatarEmail).then(payload => {
        const url = payload.data
        if (url) {
          showImage(url + '?s=224')
          emit('gravatarUrl', url + '?s=224')
        }
      })
    }

    return {
      fileInput,
      imagePreview,
      imagePreviewLoaded,
      imageTransitionName,
      selectImage,
      deleteImage,
      onFilePicked,
      checkForGravatar,
    }
  },
}
</script>
